@mixin propriete_x($propriete, $first_value, $max_widths_values) {
    @if $first_value {
        #{$propriete}: $first_value;
    }

    @each $max_width, $value in $max_widths_values {
        @if $value {
            @media (max-width: $max_width) {
                #{$propriete}: $value;
            }
        }
    }
}

.video_wrp {
    background-color: rgb(42, 42, 92);
}

.spinner_wrp {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    &.hide {
        display: none;
    }

    .spinner_border {
        display: inline-block;
        border-right-color: transparent !important;
        border-radius: 50%;
        animation: spinner_border 0.75s linear infinite;

        @include propriete_x(
            width,
            110px,
            (
                "1920px": 5.677vw,
                "991px": 8.577vw,
                "768px": 10.417vw,
                "480px": 15.417vw,
            )
        );

        @include propriete_x(
            height,
            110px,
            (
                "1920px": 5.677vw,
                "991px": 8.577vw,
                "768px": 10.417vw,
                "480px": 15.417vw,
            )
        );

        @include propriete_x(
            border,
            2px solid currentColor,
            (
                "1920px": 0.105vw solid currentColor,
                "991px": 0.202vw solid currentColor,
                "768px": 0.13vw solid currentColor,
                "480px": 0.208vw solid currentColor,
            )
        );
    }
}

@keyframes spinner_border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.section_title {
    display: flex;

    .content {
        position: relative;
        width: 100%;
        background-image: linear-gradient(to top, #000 0, transparent 100%);
        margin: auto 0 0;

        @include propriete_x(
            padding,
            45px 25px 85px,
            (
                "1920px": 2.344vw 1.302vw 4.375vw,
                "991px": 3.532vw 1.917vw 2.559vw,
                "768px": 4.297vw 2.344vw 2.073vw,
                "480px": 6.25vw 3.542vw 2.875vw,
            )
        );
    }

    .title {
        color: #fff;
        text-align: center;

        @include propriete_x(
            font-size,
            25px,
            (
                "1920px": 1.302vw,
                "991px": 1.917vw,
                "768px": 2.344vw,
                "480px": 3.542vw,
            )
        );
    }
}

.section_result {
    display: flex;

    .content {
        position: relative;
        margin: auto;
        background-image: linear-gradient(to bottom, #000 0, transparent 250%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include propriete_x(
            padding,
            25px,
            (
                "1920px": 1.302vw,
                "991px": 1.917vw,
                "768px": 2.344vw,
                "480px": 3.542vw,
            )
        );
    }

    .blk {
        text-align: center;

        @include propriete_x(
            max-width,
            950px,
            (
                "1920px": 49.01vw,
                "991px": 73.865vw,
                "768px": 90.365vw,
                "480px": 100vw,
            )
        );
    }

    .ico_wrp {
        display: inline-block;

        @include propriete_x(
            width,
            250px,
            (
                "1920px": 12.917vw,
                "991px": 19.475vw,
                "768px": 23.828vw,
                "480px": 35vw,
            )
        );

        @include propriete_x(
            height,
            250px,
            (
                "1920px": 12.917vw,
                "991px": 19.475vw,
                "768px": 23.828vw,
                "480px": 35vw,
            )
        );

        @include propriete_x(
            margin,
            0 0 25px,
            (
                "1920px": 0 0 1.302vw,
                "991px": 0 0 1.917vw,
                "768px": 0 0 2.344vw,
                "480px": 0 0 3.542vw,
            )
        );

        .ico {
            position: relative;
            width: 100%;
        }
    }

    .text {
        color: #fff;
        text-align: center;

        @include propriete_x(
            font-size,
            25px,
            (
                "1920px": 1.302vw,
                "991px": 1.917vw,
                "768px": 2.344vw,
                "480px": 3.542vw,
            )
        );
    }

    &.good {
        .text {
            color: #fff;
        }
    }

    &.fail {
        .text {
            color: #fff;
        }
    }
}

.section_choices {
    display: flex;

    .content {
        width: 100%;
        margin: auto auto 0;
        text-align: center;
        /*background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 15%,
            rgba(0, 0, 0, 0.6) 85%,
            rgba(0, 0, 0, 0) 100%
        );*/
        color: #fff;
        background-image: linear-gradient(to top, #000 0, transparent 100%);

        @include propriete_x(
            padding,
            90px 0,
            (
                "1920px": 4.635vw 0,
                "991px": 6.963vw 0,
                "768px": 8.594vw 0,
                "480px": 12.5vw 0,
            )
        );

        .ttl {
            position: relative;

            @include propriete_x(
                font-size,
                35px,
                (
                    "1920px": 1.823vw,
                    "991px": 2.725vw,
                    "768px": 3.385vw,
                    "480px": 4.792vw,
                )
            );
        }
    }

    .choices_wrp {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 0;
        overflow: hidden;

        .choice_list {
            position: relative;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .choice_item {
            position: relative;
            width: 33.33%;

            @include propriete_x(
                padding,
                25px,
                (
                    "1920px": 1.302vw,
                    "991px": 1.917vw,
                    "768px": 2.344vw,
                    "480px": 3.542vw,
                )
            );
        }

        .choice_content {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s linear;

            @include propriete_x(
                padding,
                25px,
                (
                    "1920px": 1.302vw,
                    "991px": 1.917vw,
                    "768px": 2.344vw,
                    "480px": 3.542vw,
                )
            );

            @include propriete_x(
                font-size,
                25px,
                (
                    "1920px": 1.302vw,
                    "991px": 1.917vw,
                    "768px": 2.344vw,
                    "480px": 3.542vw,
                )
            );

            @include propriete_x(
                border,
                #fff solid 2px,
                (
                    "1920px": #fff solid 0.104vw,
                    "991px": #fff solid 0.202vw,
                    "768px": #fff solid 0.13vw,
                    "480px": #fff solid 0.208vw,
                )
            );

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .timing_wrp {
        position: absolute;

        @include propriete_x(
            right,
            16px,
            (
                "1920px": 0.833vw,
                "991px": 1.211vw,
                "768px": 1.563vw,
                "480px": 2.292vw,
            )
        );

        @include propriete_x(
            top,
            18px,
            (
                "1920px": 0.938vw,
                "991px": 1.413vw,
                "768px": 1.693vw,
                "480px": 2.5vw,
            )
        );
    }
}

.section_offer {
    position: relative;
    display: flex;
    background: rgb(42, 42, 92);
    flex-wrap: wrap;

    .content {
        width: 100%;
        text-align: center;

        @include propriete_x(
            margin,
            auto 110px,
            (
                "1920px": auto 5.677vw,
                "991px": auto 8.577vw,
                "768px": auto 10.417vw,
                "480px": auto 15.417vw,
            )
        );
    }

    .logo_wrp {
        width: 100%;
        height: 0;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
    }

    .logo {
        position: relative;
        height: auto;

        @include propriete_x(
            width,
            200px,
            (
                "1920px": 10.313vw,
                "991px": 15.54vw,
                "768px": 19.01vw,
                "480px": 27.917vw,
            )
        );

        @include propriete_x(
            margin,
            45px,
            (
                "1920px": 2.344vw,
                "991px": 3.532vw,
                "768px": 4.297vw,
                "480px": 1.25vw,
            )
        );
    }

    .text {
        color: #fff;
        font-weight: normal;
        line-height: normal;

        @include propriete_x(
            font-size,
            35px,
            (
                "1920px": 1.823vw,
                "991px": 2.725vw,
                "768px": 3.385vw,
                "480px": 2.792vw,
            )
        );

        @include propriete_x(
            margin,
            null,
            (
                "480px": 6vw 0 0,
            )
        );
    }

    .btn_wrp {
        position: relative;
        display: inline-block;
        @include propriete_x(
            margin,
            45px 0 0,
            (
                "1920px": 2.344vw 0 0,
                "991px": 3.532vw 0 0,
                "768px": 4.297vw 0 0,
                "480px": 6.25vw 0 0,
            )
        );
    }

    .app_btn {
        display: inline-block;
        color: #fff;
        cursor: pointer;
        line-height: normal;
        transition: all 0.3s linear;

        @include propriete_x(
            font-size,
            35px,
            (
                "1920px": 1.823vw,
                "991px": 2.725vw,
                "768px": 3.385vw,
                "480px": 2.792vw,
            )
        );

        @include propriete_x(
            padding,
            25px,
            (
                "1920px": 1.302vw,
                "991px": 1.917vw,
                "768px": 2.344vw,
                "480px": 3.542vw,
            )
        );

        @include propriete_x(
            border,
            #fff solid 2px,
            (
                "1920px": #fff solid 0.104vw,
                "991px": #fff solid 0.202vw,
                "768px": #fff solid 0.13vw,
                "480px": #fff solid 0.208vw,
            )
        );

        &:hover {
            transform: scale(1.05);
        }
    }
}
