* {
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

*:focus {
    outline: none
}

:link {
    color: inherit;
    text-decoration: none
}

iframe,
img {
    border: none
}

.lv-player,
.ractive-player {
    //--lv-aspect-ratio: 1.6;
    --lv-aspect-ratio: 1.8;
    --lv-controls-height: 44px;
    --lv-elapsed-color: #2B3797;
    --lv-scrubber-color: #2B3797;
    --lv-scrub-height: 6px;
    --lv-volume-color: #2B3797;
    --lv-buttons-height: calc(var(--lv-controls-height) - var(--lv-scrub-height));
    --lv-canvas-height: calc(var(--lv-height) - var(--lv-controls-height));
    --lv-height: calc(var(--lv-width) / var(--lv-aspect-ratio));
    background-color: #000;
    position: relative;
    height: var(--lv-height);
    width: var(--lv-width)
}

.ractive-player {
    --rp-controls-height: var(--lv-controls-height);
    --rp-scrub-height: var(--lv-scrub-height);
    --rp-buttons-height: var(--lv-buttons-height);
    --rp-height: var(--lv-height);
    --rp-width: var(--lv-width)
}

.lv-player,
.ractive-player {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0
}

.lv-canvas,
.rp-canvas {
    background-color: #fff;
    position: relative;
    user-select: none;
    -webkit-user-select: none
}

.lv-canvas,
.rp-canvas {
    height: var(--lv-height);
    width: var(--lv-width)
}

.not-ready *[data-from-first],
.not-ready *[data-during] {
    visibility: hidden
}

@media (min-aspect-ratio:8/5) {
    :root {
        font-size: 2vh
    }

    .lv-player {
        --lv-width: 180vh
    }

    .lv-player>.lv-canvas {
        margin: 0 auto
    }

    .ractive-player {
        --lv-width: 180vh
    }

    .ractive-player>.rp-canvas {
        margin: 0 auto
    }
}

@media (max-aspect-ratio:8/5) {
    :root {
        font-size: 1.25vw
    }

    .lv-player {
        --lv-width: 100vw
    }

    .lv-player>.lv-canvas {
        top: calc((100% - var(--lv-height)) / 2)
    }

    .ractive-player {
        --lv-width: 100vw
    }

    .ractive-player>.rp-canvas {
        top: calc((100% - var(--lv-height)) / 2)
    }
}

.lv-controls,
.rp-controls {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    height: var(--lv-controls-height);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: opacity .25s cubic-bezier(0, 0, .2, 1);
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    z-index: 1000
}

.lv-controls.hidden,
.rp-controls.hidden {
    opacity: 0;
    transition: opacity .1s cubic-bezier(.4, 0, 1, 1)
}

.lv-controls,
.rp-controls {
    top: calc(50vh + var(--lv-height) / 2 - var(--lv-controls-height));
    left: calc(50vw - var(--lv-width) / 2);
    width: var(--lv-width)
}

.lv-controls-scrub,
.rp-controls-scrub {
    cursor: pointer;
    height: var(--lv-scrub-height);
    margin: 0 auto;
    position: relative;
    width: 97%;
    z-index: 2
}

.lv-controls-buttons,
.rp-controls-buttons {
    height: var(--lv-buttons-height);
    line-height: var(--lv-buttons-height);
    margin: 0 auto;
    width: 97%
}

.lv-controls-buttons>*,
.rp-controls-buttons>* {
    display: inline-block;
    height: 100%;
    vertical-align: top
}

.lv-controls-right,
.rp-controls-right {
    float: right
}

.lv-controls-right>*,
.rp-controls-right>* {
    display: inline-block;
    height: 100%;
    vertical-align: top
}

.lv-controls-buttons>button,
.lv-controls-right>button,
.rp-controls-right>button,
.lv-controls-volume>button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: .9;
    transition: opacity .1s cubic-bezier(.4, 0, 1, 1);
    height: 100%;
    padding: 0;
    width: 38px
}

.lv-controls-buttons>button:hover,
.lv-controls-right>button:hover,
.rp-controls-right>button:hover,
.lv-controls-volume>button:hover {
    opacity: 1;
    transition: opacity .1s cubic-bezier(0, 0, .2, 1)
}

.lv-controls-buttons>button>svg,
.lv-controls-right>button>svg,
.rp-controls-right>button>svg,
.lv-controls-volume>button>svg {
    height: 100%
}

.lv-controls-playpause,
.lv-controls-fullscreen,
.rp-controls-playpause,
.rp-controls-fullscreen {
    cursor: pointer
}

.lv-controls-captions,
.rp-controls-captions {
    position: relative
}

.lv-controls-captions>svg>path,
.rp-controls-captions>svg>path {
    fill: #fff
}

.lv-controls-captions::after,
.rp-controls-captions::after {
    content: "";
    background: #af1866;
    border-radius: 2px;
    bottom: 3px;
    display: block;
    height: 2px;
    left: 18px;
    position: absolute;
    width: 0;
    transition: left .1s cubic-bezier(.4, 0, 1, 1), width .1s cubic-bezier(.4, 0, 1, 1)
}

.lv-captions .lv-controls-captions::after,
.lv-captions .rp-controls-captions::after,
.rp-captions .lv-controls-captions::after,
.rp-captions .rp-controls-captions::after {
    width: 30px;
    left: 3px;
    transition: left .25s cubic-bezier(0, 0, .2, 1), width .25s cubic-bezier(0, 0, .2, 1)
}

.lv-captions-display,
.rp-captions-display {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    display: none;
    font-family: sans-serif;
    font-size: 1.3em;
    z-index: 500;
    padding: .7em;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: auto
}

.lv-captions>.lv-captions-display:not(:empty),
.rp-captions>.rp-captions-display:not(:empty) {
    display: block
}

.lv-controls-scrub,
.rp-controls-scrub {
    -webkit-tap-highlight-color: transparent
}

.lv-controls-scrub-wrap,
.rp-controls-scrub-wrap {
    height: 100%;
    width: 100%
}

.lv-controls-scrub-progress,
.rp-controls-scrub-progress {
    display: block;
    height: 100%;
    width: 100%;
    pointer-events: none
}

.lv-progress-elapsed,
.rp-progress-elapsed {
    fill: var(--lv-elapsed-color)
}

.lv-progress-remaining,
.rp-progress-remaining {
    fill: rgba(255, 255, 255, 0.6)
}

.controls-progress-buffered {
    fill: #ffa500
}

.lv-scrubber,
.rp-scrubber {
    height: 13px;
    position: absolute;
    top: -3px
}

.lv-scrubber>circle,
.rp-scrubber>circle {
    fill: var(--lv-scrubber-color)
}

.lv-thumb-highlight,
.rp-thumb-highlight {
    fill: #fff
}

.lv-thumb-highlight.past,
.rp-thumb-highlight.past {
    fill: #ec76b3
}

.lv-controls-settings,
.rp-controls-settings {
    position: relative
}

.lv-controls-settings>svg,
.rp-controls-settings>svg {
    cursor: pointer;
    height: 100%;
    padding: 6px
}

.lv-settings-dialog,
.rp-settings-dialog {
    background-color: rgba(32, 32, 32, 0.85);
    border-radius: 3px 3px 0 0;
    padding: 2px 0;
    position: absolute;
    bottom: 42px;
    right: 0;
    width: 10em
}

.lv-settings-dialog>table,
.rp-settings-dialog>table {
    border-collapse: collapse;
    width: 100%
}

.lv-settings-dialog>table>tbody>tr,
.rp-settings-dialog>table>tbody>tr {
    cursor: pointer;
    font-family: sans-serif
}

.lv-settings-dialog>table>tbody>tr:hover,
.rp-settings-dialog>table>tbody>tr:hover {
    background-color: rgba(72, 72, 72, 0.85)
}

.lv-settings-dialog>table>tbody>tr>th,
.rp-settings-dialog>table>tbody>tr>th {
    padding: .25em 0 .25em .5em;
    vertical-align: middle;
    text-align: left
}

.lv-settings-dialog>table>tbody>tr>td,
.rp-settings-dialog>table>tbody>tr>td {
    padding: .25em .5em .25em 0;
    text-align: right;
    vertical-align: middle
}

.lv-settings-speed-dialog,
.lv-settings-captions-dialog,
.rp-settings-speed-dialog {
    background-color: rgba(32, 32, 32, 0.85);
    padding: 2px 0;
    position: absolute;
    bottom: 42px;
    line-height: 1rem;
    right: 0;
    width: 6em
}

.lv-settings-speed-dialog>ul,
.lv-settings-captions-dialog>ul,
.rp-settings-speed-dialog>ul {
    list-style-type: none
}

.lv-settings-speed-dialog>ul>li,
.lv-settings-captions-dialog>ul>li,
.rp-settings-speed-dialog>ul>li {
    cursor: pointer;
    padding: 4px .5em
}

.lv-settings-speed-dialog>ul>li:hover,
.lv-settings-captions-dialog>ul>li:hover,
.rp-settings-speed-dialog>ul>li:hover {
    background-color: rgba(72, 72, 72, 0.85)
}

.lv-settings-speed-dialog>ul>li.selected,
.lv-settings-captions-dialog>ul>li.selected,
.rp-settings-speed-dialog>ul>li.selected {
    color: #af1866
}

.lv-dialog-subtitle,
.rp-dialog-subtitle {
    border-bottom: 1px solid #aaa;
    display: block;
    text-align: center
}

.lv-controls-thumbnail,
.rp-controls-thumbnail {
    background: #333;
    font-size: .85em;
    padding: 3px;
    bottom: 13px;
    text-align: center;
    position: absolute;
    margin-left: -83px
}

.lv-thumbnail-box,
.rp-thumbnail-box {
    overflow: hidden;
    height: 100px;
    width: 160px;
    margin: 0 auto;
    position: relative;
    text-align: center
}

.lv-thumbnail-box>img,
.rp-thumbnail-box>img {
    position: absolute
}

.lv-thumbnail-title,
.rp-thumbnail-title {
    color: #fff;
    font-family: sans-serif
}

.lv-thumbnail-time,
.rp-thumbnail-time {
    color: #fff;
    font-family: sans-serif;
    position: absolute;
    bottom: .5em;
    text-align: center;
    width: 100%;
    left: 0
}

.lv-controls-time,
.rp-controls-time {
    display: inline-block;
    font-family: sans-serif;
    font-size: 11px;
    line-height: 36px;
    padding: 0 1.5em;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top
}

.lv-time-separator,
.rp-time-separator {
    margin: 0 3px
}

.lv-controls-volume,
.rp-controls-volume {
    cursor: pointer;
    z-index: 100000
}

.lv-controls-volume>svg,
.rp-controls-volume>svg {
    height: 100%;
    padding: 3px
}

.lv-controls-volume>button>svg,
.rp-controls-volume>button>svg {
    padding: 3px
}

.lv-controls-volume>input[type=range],
.rp-controls-volume>input[type=range] {
    background: transparent;
    width: 10em;
    height: 100%;
    -webkit-appearance: none
}

.lv-controls-volume>input[type=range]::-webkit-slider-thumb,
.rp-controls-volume>input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.1em;
    width: 1.1em;
    background: var(--lv-volume-color);
    border: 1px solid #000;
    border-radius: 1em;
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
    cursor: pointer;
    margin-top: -.5em;
    line-height: 1.5em;
    vertical-align: middle
}

.lv-controls-volume>input[type=range]::-moz-range-thumb,
.rp-controls-volume>input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1.1em;
    width: 1.1em;
    background: var(--lv-volume-color);
    border: 1px solid #000;
    border-radius: 1em;
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
    cursor: pointer;
    margin-top: -.5em;
    line-height: 1.5em;
    vertical-align: middle
}

.lv-controls-volume>input[type=range]::-ms-thumb,
.rp-controls-volume>input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    height: 1.1em;
    width: 1.1em;
    background: var(--lv-volume-color);
    border: 1px solid #000;
    border-radius: 1em;
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
    cursor: pointer;
    margin-top: -.5em;
    line-height: 1.5em;
    vertical-align: middle
}

.lv-controls-volume>input[type=range]::-webkit-slider-runnable-track,
.rp-controls-volume>input[type=range]::-webkit-slider-runnable-track {
    height: 2px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    margin: auto 4px;
    vertical-align: middle;
    line-height: 1.5em
}

.lv-controls-volume>input[type=range]::-moz-range-track,
.rp-controls-volume>input[type=range]::-moz-range-track {
    height: 2px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    margin: auto 4px;
    vertical-align: middle;
    line-height: 1.5em
}

.lv-controls-volume>input[type=range]::-ms-track,
.rp-controls-volume>input[type=range]::-ms-track {
    height: 2px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    margin: auto 4px;
    vertical-align: middle;
    line-height: 1.5em
}

@media (min-width:401px) {

    .lv-controls,
    .rp-controls {
        --lv-controls-left: 2;
        --lv-controls-right: 3
    }
}

@media (max-width:800px) {

    .lv-player,
    .ractive-player {
        --lv-controls-height: 36px
    }
}

@media (max-width:400px) {

    .lv-player,
    .ractive-player {
        --lv-controls-height: 30px
    }

    .lv-controls,
    .rp-controls {
        --lv-controls-left: 2;
        --lv-controls-right: 2
    }

    .lv-controls-settings,
    .rp-controls-settings {
        display: none
    }
}

@media (any-hover:none) {

    .lv-controls,
    .rp-controls {
        color: #fff;
        height: var(--lv-controls-height);
        position: absolute;
        top: unset;
        transition: opacity .25s cubic-bezier(0, 0, .2, 1);
        width: var(--lv-width);
        bottom: calc((100% - var(--lv-height)) / 2);
        user-select: none;
        -webkit-user-select: none;
        z-index: 1000;
        left: calc((100% - var(--lv-width)) / 2)
    }

    .lv-controls-volume,
    .rp-controls-volume {
        display: none !important
    }

    .lv-controls-time,
    .rp-controls-time {
        line-height: unset
    }

    .lv-current-time,
    .lv-total-time,
    .rp-current-time,
    .rp-total-time {
        height: var(--lv-buttons-height);
        line-height: var(--lv-buttons-height);
        position: absolute;
        top: 0;
        width: var(--lv-buttons-height)
    }

    .lv-current-time,
    .rp-current-time {
        left: 0;
        text-align: right
    }

    .lv-total-time,
    .rp-total-time {
        right: calc((var(--lv-controls-right) - 1) * var(--lv-buttons-height));
        text-align: left
    }

    .lv-time-separator,
    .rp-time-separator {
        display: none
    }

    .lv-controls-scrub-wrap,
    .rp-controls-scrub-wrap {
        position: relative
    }

    .lv-controls-scrub-wrap::after,
    .rp-controls-scrub-wrap::after {
        content: "";
        position: absolute;
        top: calc(var(--lv-buttons-height) * (0.075 / 0.55 - 0.5));
        bottom: calc(var(--lv-buttons-height) * (0.075 / 0.55 - 0.5));
        left: 0;
        right: 0
    }

    .lv-controls-scrub,
    .rp-controls-scrub {
        height: calc(7.5% / 0.55);
        top: unset;
        top: calc(50% - 0.5 * (7.5% / 0.55));
        width: calc(100% - (var(--lv-controls-left) + var(--lv-controls-right)) * var(--lv-buttons-height) - 7px);
        left: calc(var(--lv-controls-left) * var(--lv-buttons-height));
        margin: unset
    }

    .lv-scrubber,
    .rp-scrubber {
        height: 216%;
        top: -50%;
        pointer-events: all
    }

    .lv-controls-playpause,
    .rp-controls-playpause {
        cursor: pointer;
        pointer-events: all;
        position: absolute;
        left: var(--lv-buttons-height);
        top: 0;
        width: var(--lv-buttons-height)
    }

    .lv-controls-right,
    .rp-controls-right {
        float: unset;
        position: absolute;
        right: 0;
        top: 0
    }

    .lv-controls-right>*,
    .rp-controls-right>* {
        width: var(--lv-buttons-height)
    }
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {

        *[data-from-first],
        *[data-during] {
            will-change: opacity
        }
    }
}