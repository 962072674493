@mixin propriete_x($propriete, $first_value, $max_widths_values) {
    @if $first_value {
        #{$propriete}: $first_value;
    }

    @each $max_width, $value in $max_widths_values {
        @if $value {
            @media (max-width: $max_width) {
                #{$propriete}: $value;
            }
        }
    }
}

.lv-controls {
    position: fixed;
    bottom: 0;
    top: unset;
    height: auto;
    background-image: linear-gradient(to top, #000 0%, transparent 100%); // comment for not displaying
    background-color: unset;

    @include propriete_x(
        padding,
        30px 45px 15px,
        (
            "1920px": 1.563vw 2.344vw 0.781vw,
            "991px": 2.321vw 3.532vw 1.211vw,
            "768px": 2.865vw 4.297vw 1.432vw,
            "480px": 4.167vw 6.25vw 2.083vw,
        )
    );

    &.hidden {
        * {
            pointer-events: none;
        }
    }

    .lv-controls-scrub {
        //display: none;
        width: 100%;

        @include propriete_x(
            top,
            -25px,
            (
                "1920px": -1.302vw,
                "991px": -1.917vw,
                "768px": -2.344vw,
                "480px": -3.542vw,
            )
        );
    }

    .lv-controls-buttons {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .lv-controls-playpause {
        position: relative;
        margin: auto auto auto 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        left: 0;
        background-color: unset;

        @include propriete_x(
            width,
            70px,
            (
                "1920px": 3.594vw,
                "991px": 5.449vw,
                "768px": 6.641vw,
                "480px": 9.792vw,
            )
        );

        @include propriete_x(
            height,
            70px,
            (
                "1920px": 3.594vw,
                "991px": 5.449vw,
                "768px": 6.641vw,
                "480px": 9.792vw,
            )
        );

        @include propriete_x(
            border,
            #fff solid 2px,
            (
                "1920px": #fff solid 0.105vw,
                "991px": #fff solid 0.202vw,
                "768px": #fff solid 0.13vw,
                "480px": #fff solid 0.208vw,
            )
        );

        svg {
            position: relative;

            @include propriete_x(
                width,
                30px,
                (
                    "1920px": 1.563vw,
                    "991px": 2.321vw,
                    "768px": 2.865vw,
                    "480px": 4.167vw,
                )
            );

            &.play {
                @include propriete_x(
                    left,
                    2px,
                    (
                        "1920px": 0.105vw,
                        "991px": 0.202vw,
                        "768px": 0.13vw,
                        "480px": 0.208vw,
                    )
                );
            }
        }

        &:hover {
            opacity: 1;
        }
    }

    .el_left {
        position: relative;
        display: flex;
        margin: auto auto auto 0;
    }

    .el_right {
        margin: auto 0;
        position: relative;
    }

    .lv-controls-volume {
        position: absolute;
        display: flex;
        opacity: 0.8;
        overflow: hidden;

        @include propriete_x(
            bottom,
            4px,
            (
                "1920px": 0.208vw,
                "991px": 0.303vw,
                "768px": 0.391vw,
                "480px": 0.625vw,
            )
        );

        @include propriete_x(
            left,
            90px,
            (
                "1920px": 4.635vw,
                "991px": 6.963vw,
                "768px": 8.594vw,
                "480px": 12.5vw,
            )
        );

        @include propriete_x(
            padding,
            10px,
            (
                "1920px": 0.521vw,
                "991px": 0.807vw,
                "768px": 0.911vw,
                "480px": 1.458vw,
            )
        );

        @include propriete_x(
            height,
            60px,
            (
                "1920px": 3.073vw,
                "991px": 4.642vw,
                "768px": 5.729vw,
                "480px": 8.333vw,
            )
        );

        @include propriete_x(
            width,
            60px,
            (
                "1920px": 3.073vw,
                "991px": 4.642vw,
                "768px": 5.729vw,
                "480px": 8.333vw,
            )
        );

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;

            @include propriete_x(
                height,
                177px,
                (
                    "1920px": 9.115vw,
                    "991px": 13.724vw,
                    "768px": 16.797vw,
                    "480px": 24.792vw,
                )
            );

            & > input {
                display: block;
                opacity: 1;
            }
        }

        & > button {
            margin: auto auto 0;
            opacity: 0.8;
            position: absolute;

            @include propriete_x(
                bottom,
                10px,
                (
                    "1920px": 0.521vw,
                    "991px": 0.807vw,
                    "768px": 0.911vw,
                    "480px": 1.458vw,
                )
            );

            @include propriete_x(
                left,
                10px,
                (
                    "1920px": 0.521vw,
                    "991px": 0.807vw,
                    "768px": 0.911vw,
                    "480px": 1.458vw,
                )
            );

            @include propriete_x(
                width,
                40px,
                (
                    "1920px": 2.083vw,
                    "991px": 3.128vw,
                    "768px": 3.776vw,
                    "480px": 5.625vw,
                )
            );

            @include propriete_x(
                height,
                40px,
                (
                    "1920px": 2.083vw,
                    "991px": 3.128vw,
                    "768px": 3.776vw,
                    "480px": 5.625vw,
                )
            );
        }

        & > input {
            transform: all 0.3s linear;
            display: none;
            opacity: 0;
            position: absolute;
            height: 0;
            transform: rotate(-90deg);
            opacity: 0.8;

            @include propriete_x(
                width,
                90px,
                (
                    "1920px": 4.635vw,
                    "991px": 6.963vw,
                    "768px": 8.594vw,
                    "480px": 12.5vw,
                )
            );

            @include propriete_x(
                top,
                65px,
                (
                    "1920px": 3.333vw,
                    "991px": 5.045vw,
                    "768px": 6.12vw,
                    "480px": 9.167vw,
                )
            );

            @include propriete_x(
                left,
                -14px,
                (
                    "1920px": -0.729vw,
                    "991px": -1.11vw,
                    "768px": -1.302vw,
                    "480px": -1.875vw,
                )
            );
        }
    }

    .lv-controls-time {
        position: absolute;

        padding: 0;
        line-height: 0;

        @include propriete_x(
            bottom,
            34px,
            (
                "1920px": 1.771vw,
                "991px": 2.624vw,
                "768px": 3.255vw,
                "480px": 4.792vw,
            )
        );

        @include propriete_x(
            left,
            165px,
            (
                "1920px": 8.49vw,
                "991px": 12.815vw,
                "768px": 15.625vw,
                "480px": 23.125vw,
            )
        );

        @include propriete_x(
            font-size,
            16px,
            (
                "1920px": 0.833vw,
                "991px": 1.211vw,
                "768px": 1.563vw,
                "480px": 2.292vw,
            )
        );
    }

    .lv-controls-fullscreen {
        position: absolute;
        right: 0;
        background-color: unset;
        border: unset;
        opacity: 0.8;

        @include propriete_x(
            bottom,
            -27px,
            (
                "1920px": -1.406vw,
                "991px": -2.119vw,
                "768px": -2.604vw,
                "480px": -3.75vw,
            )
        );

        @include propriete_x(
            width,
            55px,
            (
                "1920px": 2.813vw,
                "991px": 4.238vw,
                "768px": 5.208vw,
                "480px": 7.708vw,
            )
        );

        @include propriete_x(
            height,
            55px,
            (
                "1920px": 2.813vw,
                "991px": 4.238vw,
                "768px": 5.208vw,
                "480px": 7.708vw,
            )
        );

        &:hover {
            opacity: 1;
        }
    }

    @media (any-hover: none) {
        .lv-controls-scrub {
            height: var(--lv-scrub-height);
            left: unset;
        }

        .lv-controls-time {
            width: initial;
            display: flex;

            @include propriete_x(
                left,
                266px,
                (
                    "1920px": 13.698vw,
                    "991px": 20.686vw,
                    "768px": 25.26vw,
                    "480px": 37.083vw,
                )
            );

            .lv-current-time {
                height: unset;
                line-height: unset;
                position: relative;
                top: unset;
                width: auto;
                display: inline-block;
                left: unset;
                text-align: unset;
            }

            .lv-time-separator {
                display: inline-block;
                width: auto;
            }

            .lv-total-time {
                right: unset;
                text-align: unset;
                height: unset;
                line-height: unset;
                position: relative;
                top: unset;
                width: auto;
                display: inline-block;
            }
        }

        .lv-controls-volume {
            display: flex !important;
            width: auto;
            // height: auto;
            //padding: 0;

            @include propriete_x(
                width,
                160px,
                (
                    "1920px": 8.229vw,
                    "991px": 12.412vw,
                    "768px": 15.234vw,
                    "480px": 22.292vw,
                )
            );

            @include propriete_x(
                left,
                90px,
                (
                    "1920px": 4.635vw,
                    "991px": 6.963vw,
                    "768px": 8.594vw,
                    "480px": 12.5vw,
                )
            );

            &:hover {
                @include propriete_x(
                    height,
                    auto,
                    (
                        "1920px": auto,
                        "991px": auto,
                        "768px": auto,
                        "480px": auto,
                    )
                );
            }

            & > input {
                left: 0;
                top: 0;
                position: relative;
                display: block;
                transform: unset;

                @include propriete_x(
                    width,
                    90px,
                    (
                        "1920px": 4.635vw,
                        "991px": 6.963vw,
                        "768px": 8.594vw,
                        "480px": 12.5vw,
                    )
                );

                @include propriete_x(
                    margin,
                    auto 8px auto auto,
                    (
                        "1920px": auto 0.417vw auto auto,
                        "991px": auto 0.605vw auto auto,
                        "768px": auto 0.781vw auto auto,
                        "480px": auto 1.042vw auto auto,
                    )
                );
            }
        }
    }

    .big_play_wrp {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;

        .btn {
            margin: auto;
            background-color: unset;
            border: unset;
            cursor: pointer;
            opacity: 0.8;

            @include propriete_x(
                width,
                120px,
                (
                    "1920px": 6.198vw,
                    "991px": 9.284vw,
                    "768px": 11.458vw,
                    "480px": 16.667vw,
                )
            );

            @include propriete_x(
                height,
                120px,
                (
                    "1920px": 6.198vw,
                    "991px": 9.284vw,
                    "768px": 11.458vw,
                    "480px": 16.667vw,
                )
            );

            &:hover {
                opacity: 1;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
