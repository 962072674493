@mixin propriete_x($propriete, $first_value, $max_widths_values) {
    @if $first_value {
        #{$propriete}: $first_value;
    }

    @each $max_width, $value in $max_widths_values {
        @if $value {
            @media (max-width: $max_width) {
                #{$propriete}: $value;
            }
        }
    }
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    right: 0;
}

section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
}

.section_conclusion {
    display: flex;

    .content {
        width: 100%;
        margin: auto;
        text-align: center;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 15%,
            rgba(0, 0, 0, 0.6) 85%,
            rgba(0, 0, 0, 0) 100%
        );
        color: #fff;

        @include propriete_x(
            padding,
            50px 0,
            (
                "1920px": 2.604vw 0,
                "991px": 3.935vw 0,
                "768px": 4.818vw 0,
                "480px": 7.083vw 0,
            )
        );

        .ttl {
            position: relative;
            position: relative;

            @include propriete_x(
                font-size,
                35px,
                (
                    "1920px": 1.823vw,
                    "991px": 2.725vw,
                    "768px": 3.385vw,
                    "480px": 4.792vw,
                )
            );
        }
    }

    .choices_wrp {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;

        .choice_list {
            position: relative;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .choice_item {
            position: relative;
            width: 100%;
            height: 0;

            @include propriete_x(
                padding,
                20px 0 0,
                (
                    "1920px": 1.042vw 0 0,
                    "991px": 1.514vw 0 0,
                    "768px": 1.953vw 0 0,
                    "480px": 2.708vw 0 0,
                )
            );
        }

        .choice_content {
            position: relative;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;

            @include propriete_x(
                font-size,
                25px,
                (
                    "1920px": 1.302vw,
                    "991px": 1.917vw,
                    "768px": 2.344vw,
                    "480px": 3.542vw,
                )
            );
        }
    }
}
