@mixin propriete_x($propriete, $first_value, $max_widths_values) {
    @if $first_value {
        #{$propriete}: $first_value;
    }

    @each $max_width, $value in $max_widths_values {
        @if $value {
            @media (max-width: $max_width) {
                #{$propriete}: $value;
            }
        }
    }
}

.playlist_wrp {
    display: flex;
    position: fixed;
    left: unset;
    bottom: unset;
    transition: background-color 0.3s linear;
    z-index: 2;

    @include propriete_x(
        top,
        15px,
        (
            "1920px": 0.781vw,
            "991px": 1.211vw,
            "768px": 1.432vw,
            "480px": 2.083vw,
        )
    );

    @include propriete_x(
        padding,
        23px 25px 34px,
        (
            "1920px": 1.198vw 1.302vw 1.771vw,
            "991px": 1.816vw 1.917vw 2.624vw,
            "768px": 2.214vw 2.344vw 3.255vw,
            "480px": 3.125vw 3.542vw 4.792vw,
        )
    );

    @include propriete_x(
        margin-left,
        -26px,
        (
            "1920px": -1.354vw,
            "991px": -2.018vw,
            "768px": -2.474vw,
            "480px": -3.542vw,
        )
    );

    @include propriete_x(
        height,
        86px,
        (
            "1920px": 4.427vw,
            "991px": 6.66vw,
            "768px": 8.203vw,
            "480px": 12.083vw,
        )
    );

    &.show_list {
        background-color: rgba(0, 0, 0, 0.5);

        .playlist_list_wrp {
            pointer-events: unset;
            opacity: 1;
        }

        .toggler {
            opacity: 1;

            span {
                &:nth-child(1) {
                    @include propriete_x(
                        left,
                        -14px,
                        (
                            "1920px": -0.729vw,
                            "991px": -1.11vw,
                            "768px": -1.302vw,
                            "480px": -1.875vw,
                        )
                    );
                }

                &:nth-child(2) {
                    @include propriete_x(
                        left,
                        16px,
                        (
                            "1920px": 0.833vw,
                            "991px": 1.211vw,
                            "768px": 1.563vw,
                            "480px": 2.292vw,
                        )
                    );
                }

                &:nth-child(3) {
                    @include propriete_x(
                        left,
                        -14px,
                        (
                            "1920px": -0.729vw,
                            "991px": -1.11vw,
                            "768px": -1.302vw,
                            "480px": -1.875vw,
                        )
                    );
                }
            }
        }
    }

    &:hover {
        .toggler {
            opacity: 1;
        }
    }

    .toggler {
        background-color: unset;
        position: relative;
        border: unset;
        overflow: hidden;
        opacity: 0.8;
        cursor: pointer;

        @include propriete_x(
            width,
            50px,
            (
                "1920px": 2.604vw,
                "991px": 3.935vw,
                "768px": 4.818vw,
                "480px": 7.083vw,
            )
        );

        @include propriete_x(
            height,
            35px,
            (
                "1920px": 1.823vw,
                "991px": 2.725vw,
                "768px": 3.385vw,
                "480px": 4.792vw,
            )
        );

        span {
            position: absolute;
            background-color: #fff;
            left: 0;
            transition: all 0.3s linear;

            @include propriete_x(
                height,
                3px,
                (
                    "1920px": 0.156vw,
                    "991px": 0.202vw,
                    "768px": 0.26vw,
                    "480px": 0.517vw,
                )
            );

            &:nth-child(1) {
                width: 100%;
                top: 0;
            }

            &:nth-child(2) {
                width: 100%;

                @include propriete_x(
                    top,
                    16px,
                    (
                        "1920px": 0.833vw,
                        "991px": 1.211vw,
                        "768px": 1.563vw,
                        "480px": 2.292vw,
                    )
                );
            }

            &:nth-child(3) {
                width: 100%;
                bottom: 0;
            }
        }
    }

    .playlist_list_wrp {
        position: absolute;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.3s linear;
        bottom: unset;
        pointer-events: none;

        @include propriete_x(
            top,
            86px,
            (
                "1920px": 4.427vw,
                "991px": 6.66vw,
                "768px": 8.203vw,
                "480px": 12.083vw,
            )
        );

        @include propriete_x(
            padding,
            5px 0,
            (
                "1920px": 0.26vw 0,
                "991px": 0.404vw 0,
                "768px": 0.521vw 0,
                "480px": 0.625vw 0,
            )
        );
    }

    .playlist_list {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .playlist_list_item {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;

        @include propriete_x(
            padding,
            0 25px,
            (
                "1920px": 0 1.302vw,
                "991px": 0 1.917vw,
                "768px": 0 2.344vw,
                "480px": 0 3.542vw,
            )
        );

        &.disabled {
            opacity: 0.5;
            cursor: initial;
        }

        &.active {
            background-color: rgba(255, 255, 255, 0.1);
        }

        &:not(.disabled):hover {
            background-color: rgba(255, 255, 255, 0.2);
        }

        &:last-child {
            .content {
                @include propriete_x(
                    border-bottom,
                    rgba(255, 255, 255, 0.8) solid 1px,
                    (
                        "1920px": rgba(255, 255, 255, 0.8) solid 0.052vw,
                        "991px": rgba(255, 255, 255, 0.8) solid 0.101vw,
                        "768px": rgba(255, 255, 255, 0.8) solid 0.13vw,
                        "480px": rgba(255, 255, 255, 0.8) solid 0.208vw,
                    )
                );
            }
        }

        .content {
            position: relative;
            white-space: nowrap;

            @include propriete_x(
                font-size,
                25px,
                (
                    "1920px": 1.302vw,
                    "991px": 1.917vw,
                    "768px": 2.344vw,
                    "480px": 3.542vw,
                )
            );

            @include propriete_x(
                padding,
                25px 0,
                (
                    "1920px": 1.302vw 0,
                    "991px": 1.917vw 0,
                    "768px": 2.344vw 0,
                    "480px": 3.542vw 0,
                )
            );

            @include propriete_x(
                border-top,
                rgba(255, 255, 255, 0.8) solid 1px,
                (
                    "1920px": rgba(255, 255, 255, 0.8) solid 0.052vw,
                    "991px": rgba(255, 255, 255, 0.8) solid 0.101vw,
                    "768px": rgba(255, 255, 255, 0.8) solid 0.13vw,
                    "480px": rgba(255, 255, 255, 0.8) solid 0.208vw,
                )
            );
        }
    }
}
